*{
    font-family: "Roboto", sans-serif;
}

.particles-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1; /* Ensure Particle.js is behind other content */
}


/* Home.css */
/* Home.css */

@keyframes textFadeIn {
from {
    opacity: 0;
    transform: translateY(10%);
}
to {
    opacity: 1;
    transform: translateY(0%);
}
}

@keyframes textFadeOut {
from {
    opacity: 1;
    transform: translateY(0%);
}
to {
    opacity: 0;
    transform: translateY(-10%);
}
}

.text-transition {
    animation-duration: 0.5s;
    animation-fill-mode: both;
    animation-name: textFadeIn;
}

.text-transition.fade-out {
    animation-name: textFadeOut;
}


.col{
    padding: 0;
    margin: 0;
}
  

/* NavBar */

/* Styles for the navigation links */
.nav-link {
    transition: background-color 0.3s;
  }
  
  /* Styles for the link when hovered */
  .nav-link:hover {
    background-color: #c3e3ef80; /* Change this color as desired */
  }
  


/* home button */
.home-button {
    font-size: 1.2rem; 
    border-radius: 10px; 
    border: 1px solid rgba(123, 118, 118, 0.218);
    background-color: #f5f5f5; 
    color: black;
}
  
.home-button:hover {
    background-color: #d9eff592; 
    color: black;
    cursor: pointer; 
}



/* Volunteering */
.show-detail{
    color: rgb(65, 65, 122);
    font-weight: bold;
}

.show-detail:hover{
    text-decoration: underline;
}


/* certificate */

.certi-heading{
    color: rgb(50, 46, 46);
    text-decoration: none;
    font-weight: bold;
}

.certi-heading:hover{
    text-decoration: underline;
}

.title{
    font-size: 1rem;
}

.description{
    font-size: 0.85rem;
}
  